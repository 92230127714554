export type CodingSoundKindKey =
  | 'EFFECT'
  | 'GAME'
  | 'BRIGHT'
  | 'LIVELY'
  | 'FUNNY'
  | 'SAD'
  | 'DARK'
  | 'TENSE'
  | 'PASSION'
  | 'GRAND'
  | 'DREAMY'
  | 'MISC';

export const CodingSoundKind: Record<CodingSoundKindKey, string> = {
  EFFECT: '효과음',
  GAME: '게임',
  BRIGHT: '밝은',
  LIVELY: '경쾌한',
  FUNNY: '코믹',
  SAD: '슬픈',
  DARK: '어두운',
  TENSE: '긴박한',
  PASSION: '열정',
  GRAND: '웅장한',
  DREAMY: '몽환적',
  MISC: '기타',
};
