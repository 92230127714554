import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@ocodelib/api-common';
import type { PagerData, SysKey, SysKeyGrp, SysKeySimple } from '../model/index';

/**
 * SysKeyManage Api
 */
export class SysKeyManageApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  keysByGrp = (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<{ keyList: SysKey[] }> => {
    const url = '/admin/sys-key/keys-by-grp';
    return this.withData.post(url, ...splitParams(params));
  };

  grpDetails = (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<{
    keyGrp: SysKeyGrp;
    keyList: SysKey[];
  }> => {
    const url = '/admin/sys-key/grp-details';
    return this.withData.post(url, ...splitParams(params));
  };

  searchKeys = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      grp?: string;
      key?: string;
      keyword?: string;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<SysKey> }> => {
    const url = '/admin/sys-key/key-search';
    return this.withData.postJson(url, ...splitParams(params));
  };

  existsGrp = (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<{
    value: boolean;
  }> => {
    const url = '/admin/sys-key/grp-check-exists';
    return this.withData.post(url, ...splitParams(params));
  };

  insertKeys = (
    params: {
      grp: string;
      grpName: string;
      keyList: Array<{
        key: string;
        keyName: string;
        keyCate?: string;
        tag?: string;
      }>;
    } & BaseRequest,
  ): Promise<{
    keyGrp: SysKeyGrp;
    keyList: SysKey[];
  }> => {
    const url = '/admin/sys-key/insert-key-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  updateKeys = (
    params: {
      grp: string;
      grpName: string;
      keyList: Array<{
        key: string;
        keyName: string;
        keyCate?: string;
        tag?: string;
      }>;
    } & BaseRequest,
  ): Promise<{
    keyGrp: SysKeyGrp;
    keyList: SysKey[];
  }> => {
    const url = '/admin/sys-key/update-key-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  deleteGrp = async (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/sys-key/grp-delete';
    await this.helper.post(url, ...splitParams(params));
  };
}
