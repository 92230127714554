export type UserKickReasonKey = 'ILGMTRL' | 'PORN' | 'SPAM' | 'ABUSE' | 'ILBE' | 'ETC';
export const UserKickReason: Record<UserKickReasonKey, string> = {
  ILGMTRL: '불법자료', // illegal material
  PORN: '음란',
  SPAM: '스팸/광고',
  ABUSE: '욕설',
  ILBE: '일베',
  ETC: '기타',
} as const;

export type SecQuestionTypeKey =
  | 'A001'
  | 'A002'
  | 'A003'
  | 'A004'
  | 'A005'
  | 'A006'
  | 'A007'
  | 'A008'
  | 'A009';

export const SecQuestionType: Record<SecQuestionTypeKey, string> = {
  A001: '가장 좋아하는 노래의 제목은 무엇입니까?',
  A002: '처음으로 여행한 도시는 어디입니까?',
  A003: '가장 좋아하는 음식은 무엇입니까?',
  A004: '가장 좋아하는 동물은 무엇입니까?',
  A005: '감명깊게 읽은 책의 제목은 무엇입니까?',
  A006: '가장 친한 친구의 이름은 무엇입니까?',
  A007: '나의 별명은?',
  A008: '아버지의 성함은 무엇입니까?',
  A009: '어머니의 성함은 무엇입니까?',
} as const;

export type UserAccountStateKey = 'ACTIVE' | 'DELETED' | 'STOPPED' | 'DORMANT' | 'PW_LOCKED';
export const UserAccountState: Record<UserAccountStateKey, string> = {
  ACTIVE: '정상',
  DELETED: '탈퇴',
  STOPPED: '일시사용중지',
  DORMANT: '휴면 계정',
  PW_LOCKED: '계정 잠금',
} as const;

export type UserWithdrawStateKey = 'PENDING' | 'FINISH' | 'ERROR';
export const UserWithdrawState: Record<UserWithdrawStateKey, string> = {
  PENDING: '처리 대기중',
  FINISH: '처리 완료',
  ERROR: '처리 실패',
};
