export type AdminLogKindKey = 'ACCESS' | 'PERSON' | 'ROLE';
export const AdminLogKind: Record<AdminLogKindKey, string> = {
  ACCESS: '접근',
  PERSON: '개인정보처리',
  ROLE: '관리자 권한',
};

export type UserLogKindKey = 'ACCESS';
export const UserLogKind: Record<UserLogKindKey, string> = {
  ACCESS: '접근',
};

export type MOLogLevelKey = 'D' | 'I' | 'W' | 'E';
export const MOLogLevel: Record<MOLogLevelKey, string> = {
  D: '디버그',
  I: '정보',
  W: '경고',
  E: '에러',
};

export type MOJobStatusKey = 'FIRST' | 'RUNNING' | 'SUCCESS' | 'ERROR';
export const MOJobStatus: Record<MOJobStatusKey, string> = {
  FIRST: '최초',
  RUNNING: '실행중',
  SUCCESS: '실행 완료',
  ERROR: '에러',
};

export type MOAuditLogKindKey = 'AUTH' | 'PRIV' | 'ROLE' | 'ETC';
export const MOAuditLogKind: Record<MOAuditLogKindKey, string> = {
  AUTH: '인증 관련',
  PRIV: '개인정보처리',
  ROLE: '권한 관리',
  ETC: '기타',
};

export type AccountStateKey = 'ACTIVE' | 'DELETED' | 'STOPPED' | 'DORMANT' | 'PW_LOCKED';

export const AccountState: Record<AccountStateKey, string> = {
  /**
   * 정상 상태
   */
  ACTIVE: '정상',

  /**
   * 삭제 또는 회원 탈퇴 상태
   */
  DELETED: '삭제',

  /**
   * 계정 일시 중지 상태, 보통 관리자가 일시적으로 특정 계정을 중지시킬때 사용
   */
  STOPPED: '계정 일시 중지',

  /**
   * 휴면 계정 상태
   */
  DORMANT: '휴면',

  /**
   * 비밀번호 변경 기간이 만료되거나
   * 반복적 로그인 실패로 계정이 잠김
   */
  PW_LOCKED: '계정 잠금',
} as const;
