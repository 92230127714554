export class CoppiaUtil {
  static loadPublicKey = (pubKeyB64: string): Promise<CryptoKey> => {
    const publicKeyBuffer = new Uint8Array(
      atob(pubKeyB64)
        .split('')
        .map((char) => char.charCodeAt(0)),
    );
    return window.crypto.subtle.importKey(
      'spki',
      publicKeyBuffer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      false,
      ['encrypt'],
    );
  };

  static loadPrivateKey = (privateKeyB64: string): Promise<CryptoKey> => {
    const privateKeyBuffer = new Uint8Array(
      atob(privateKeyB64)
        .split('')
        .map((char) => char.charCodeAt(0)),
    );

    return window.crypto.subtle.importKey(
      'pkcs8',
      privateKeyBuffer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      false,
      ['decrypt'],
    );
  };

  static encryptText = async (publicKey: CryptoKey, plainText: string): Promise<string> => {
    // 텍스트를 ArrayBuffer로 변환
    const textEncoder = new TextEncoder();
    const plaintextBuffer = textEncoder.encode(plainText);

    // RSA 암호화
    const encryptedBuffer = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      publicKey,
      plaintextBuffer,
    );

    // 암호화된 데이터를 Base64로 인코딩
    // @ts-ignore Argument of type 'Uint8Array' is not assignable to parameter of type 'number[]'
    const encryptedText = btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedBuffer))); // es5
    // const encryptedText = btoa(String.fromCharCode(...new Uint8Array(encryptedBuffer))) // es6

    return encryptedText;
  };

  decryptText = async (privateKey: CryptoKey, encryptedTextB64: string): Promise<string> => {
    // Base64로 인코딩된 암호화된 텍스트를 ArrayBuffer로 변환
    const encryptedBuffer = new Uint8Array(
      atob(encryptedTextB64)
        .split('')
        .map((char) => char.charCodeAt(0)),
    );

    // RSA 복호화
    const decryptedBuffer = await window.crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP',
      },
      privateKey,
      encryptedBuffer,
    );

    // 복호화된 데이터를 문자열로 변환
    const textDecoder = new TextDecoder();
    const plaintext = textDecoder.decode(decryptedBuffer);

    return plaintext;
  };
}
