export type BooleanSearchTypeKey = 'BOTH' | 'SUCCESS_ONLY' | 'FAIL_ONLY';
export const BooleanSearchType: Record<BooleanSearchTypeKey, string> = {
  BOTH: '모두',
  SUCCESS_ONLY: '성공만',
  FAIL_ONLY: '실패만',
};

export type RunningStatusKey = 'FIRST' | 'RUNNING' | 'SUCCESS' | 'ERROR';
export const RunningStatus: Record<RunningStatusKey, string> = {
  FIRST: '최초',
  RUNNING: '실행중',
  SUCCESS: '실행 완료',
  ERROR: '에러',
};

export type LoadingStateKey = 'NONE' | 'LOADING' | 'SUCCESS' | 'ERROR';
export const LoadingState: Record<LoadingStateKey, string> = {
  NONE: '최초',
  LOADING: '로딩중',
  SUCCESS: '완료',
  ERROR: '에러',
};

/**
 * 3가지 On/Off 상태
 */
export type TriOnOffStateKey = 'none' | 'on' | 'off';

/**
 * 3가지 Select 상태
 */
export type TriSelectStateKey = 'none' | 'selected' | 'unselected';

/**
 * 3가지 Visible 상태
 */
export type TriVisibleStateKey = 'none' | 'visible' | 'invisible';

export type OpenSourceCateKey = 'ANDROID' | 'IOS' | 'SERVER' | 'WEB' | 'HWPC';
export const OpenSourceCate: Record<OpenSourceCateKey, string> = {
  WEB: '웹',
  SERVER: '서버',
  ANDROID: '안드로이드',
  IOS: 'iOS',
  HWPC: '하드웨어 PC프로그램',
};

export type ClauseAgreeTypeKey = 'MANDATORY' | 'OPTIONAL' | 'DISPLAY';
export const ClauseAgreeType: Record<ClauseAgreeTypeKey, string> = {
  MANDATORY: '필수',
  OPTIONAL: '선택',
  DISPLAY: '표시',
};
