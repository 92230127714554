export type DatiDomainTypeKey = 'SYS' | 'USER';
export const DatiDomainType: Record<DatiDomainTypeKey, string> = {
  SYS: '시스템',
  USER: '사용자',
};

export type DatiReadPolicyKey = 'PUB' | 'PRIV' | 'MEM' | 'LOCKED';
export const DatiReadPolicy: Record<DatiReadPolicyKey, string> = {
  PUB: '공개',
  PRIV: '비공개',
  MEM: '멤버허용',
  LOCKED: '잠금',
};

export type DatiWritePolicyKey = 'PUB' | 'PRIV' | 'MEM' | 'LOCKED';
export const DatiWritePolicy: Record<DatiWritePolicyKey, string> = {
  PUB: '공개',
  PRIV: '비공개',
  MEM: '멤버허용',
  LOCKED: '잠금',
};

export type DatiZipRequestStateKey = 'REQ' | 'RUNNING' | 'CANCEL' | 'ABSENT' | 'SUCCESS';
export const DatiZipRequestState: Record<DatiZipRequestStateKey, string> = {
  REQ: '요청',
  RUNNING: '실행중',
  CANCEL: '취소',
  ABSENT: '삭제됨',
  SUCCESS: '압축완료',
};
