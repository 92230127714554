import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@ocodelib/api-common';
import type { SysKeySimple } from '../model/index';

/**
 * SysKey Api
 */
export class SysKeyApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  keysByGrp = (
    params: {
      grp: string;
    } & BaseRequest,
  ): Promise<{ keyList: SysKeySimple[] }> => {
    const url = `/p/api/sys-key/keys-by-grp`;
    return this.withData.get(url, ...splitParams(params));
  };

  searchKeys = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      grp?: string;
      grpName?: string;
      key?: string;
      keyName?: string;
      keyCate?: string;
      includeDeleted: boolean;
    } & BaseRequest,
  ): Promise<{ keyList: SysKeySimple[] }> => {
    const url = `/p/api/sys-key/key-search`;
    return this.withData.get(url, ...splitParams(params));
  };
}
