import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@ocodelib/api-common';
import type { DatiDomainTypeKey, DatiReadPolicyKey, DatiWritePolicyKey } from '../enum-types/index';
import type { Dati, DatiDomain } from '../model/dati/index';
import type { PagerData } from '../model/index';

/**
 * 다티 도메인 Api
 */
export class DatiDomainApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 도메인 목록
   */
  list = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      domainId?: string;
      domainName?: string;
      domainType?: DatiDomainTypeKey;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<DatiDomain> }> => {
    const url = '/admin/dati-domain/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 도메인 정보
   */
  info = (params: { domainId: string } & BaseRequest): Promise<{ datiDomain: DatiDomain }> => {
    const url = '/admin/dati-domain/info';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 도메인 신규 등록
   */
  create = (
    params: {
      domainId: string;
      domainName: string;
      domainType: DatiDomainTypeKey;
    } & BaseRequest,
  ): Promise<{ datiDomain: DatiDomain }> => {
    const url = '/admin/dati-domain/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 도메인 업데이트
   */
  update = (
    params: {
      domainId: string;
      domainName: string;
      domainType: DatiDomainTypeKey;
    } & BaseRequest,
  ): Promise<{ datiDomain: DatiDomain }> => {
    const url = '/admin/dati-domain/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 도메인 삭제
   */
  delete = async (
    params: {
      domainId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/dati-domain/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * 다티 목록
   */
  datiList = (
    params: {
      pageNumber: number;
      rowsPerPage: number;
      domainId: string;
      withDelete: boolean;
    } & BaseRequest,
  ): Promise<{ pagerData: PagerData<Dati> }> => {
    const url = '/admin/dati-domain/dati-list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 다티 신규 등록
   */
  createDati = (
    params: {
      domainId: string;
      datiPartId?: string;
      folder: string;
      readPolicy: DatiReadPolicyKey;
      writePolicy: DatiWritePolicyKey;
    } & BaseRequest,
  ): Promise<{ dati: Dati }> => {
    const url = '/admin/dati-domain/dati-create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 다티 수정
   */
  updateDati = (
    params: {
      datiId: string;
      readPolicy: DatiReadPolicyKey;
      writePolicy: DatiWritePolicyKey;
    } & BaseRequest,
  ): Promise<{ dati: Dati }> => {
    const url = '/admin/dati-domain/dati-update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 다티 정보 조회
   */
  datiInfo = (
    params: {
      datiId: string;
    } & BaseRequest,
  ): Promise<{ dati: Dati }> => {
    const url = '/admin/dati-domain/dati-info';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 다티 삭제
   */
  deleteDati = async (
    params: {
      datiId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/dati-domain/dati-delete';
    await this.helper.post(url, ...splitParams(params));
  };
}
