export type DtbPublStateKey = 'PEND' | 'OK' | 'HIDE' | 'DEL';
export const DtbPublState: Record<DtbPublStateKey, string> = {
  PEND: '대기중', // 배포된적 없음
  OK: '정상',
  HIDE: '숨김 상태', // 본인이 게시물을 감춘 경우
  DEL: '삭제',
};

export type DtbItemTypeKey = 'SECTN' | 'BOOK';
export const DtbItemType: Record<DtbItemTypeKey, string> = {
  SECTN: '섹션',
  BOOK: '문서',
};

export type DtbListFilterTypeKey = 'ONLY_PUBLISHED' | 'ONLY_DRAFT' | 'BOTH';
export const DtbListFilterType: Record<DtbListFilterTypeKey, string> = {
  ONLY_PUBLISHED: '배포된 버전만 조회',
  ONLY_DRAFT: '드래프트만 조회',
  BOTH: '모두',
};
