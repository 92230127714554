const serverErrorMessages: Record<string, string> = {
  E1_ABUSE: '부적절한 API 사용입니다',
  E1_ACCESS_DENIED: '접근이 차단되었습니다',
  E1_AUTH_INVALID_TOKEN: '인증 토큰이 만료되었습니다',
  E1_BLK_NO_SUCH_BLOCK: '해당 블록이 존재하지 않습니다',
  E1_BLK_NO_SUCH_WORKSPACE: '해당 워크 스페이스가 존재하지 않습니다',
  E1_BLK_TOOLBOX_ITEM_SORT_COUNT_MISMATCH: '툴박스 아이템의 개수가 일치하지 않습니다',
  E1_BLK_WORKSPACE_NOT_READY: '블록 워크스페이스가 준비되지 않았습니다',
  E1_BLK_XML_FORMAT_ERROR: 'XML 포맷이 올바르지 않습니다',
  E1_CANCELED: '취소되었습니다',
  E1_CLAUSE_DATE_DUP: '약관의 시행일자가 중복됩니다.',
  E1_DENY_SELF_FAVOR: '본인의 글에는 좋아요를 할 수 없습니다',
  E1_HTTP_400: '올바른 요청이 아닙니다',
  E1_HTTP_401: '로그인이 필요합니다',
  E1_HTTP_404: '해당 주소를 찾을 수 없습니다',
  E1_HTTP_500: '서버 오류가 발생했습니다',
  E1_HTTP_502: '서버 연결이 불안정합니다',
  E1_HTTP_FORBIDDEN: '로그아웃 되었습니다',
  E1_INVALID_PARAM: '올바르지 않은 파라미터입니다',
  E1_INVALID_PW_TOKEN: '비밀번호 변경 토큰이 올바르지 않습니다(또는 토큰만료)',
  E1_LOGIN_FAIL: '로그인 정보가 올바르지 않습니다',
  E1_NO_SEC_QUESTION: '설정한 보안 질문이 없습니다',
  E1_NO_SUCH_ARTICLE: '해당 게시물이 존재하지 않습니다',
  E1_NO_SUCH_CLAUSE: '해당 약관이 없습니다',
  E1_NO_SUCH_CLAUSE_SUBSTANCE: '해당 약관 내용이 없습니다',
  E1_NO_SUCH_DATA: '관련 자료가 없습니다',
  E1_NO_SUCH_FAQ: '해당 FAQ가 없습니다',
  E1_NO_SUCH_USER: '해당 사용자가 없습니다',
  E1_DATA_DUP: '데이터가 중복됩니다',
  E1_DATA_IN_USE: '데이터가 사용중입니다',
  E1_NOT_ARTICLE_OWNER: '게시물의 작성자가 아닙니다',
  E1_UNKNOWN: '오류가 발생했습니다',
  E1_USER_ID_DUP: '사용자ID가 중복됩니다',
  E1_NICK_NAME_DUP: '닉네임이 중복됩니다',
  E1_DENY_UNDERBAR_USER_ID: '사용자ID는 언더바(_)로 시작할 수 없습니다',
  E1_DENY_UNDERBAR_NICKNAME: '닉네임은 언더바(_)로 시작할 수 없습니다',
  E1_WRONG_CAPTCHA_ANSWER: '보안문자가 올바르지 않습니다',
  E1_CAPTCHA_VERIFY_FAIL: '캡차 인증이 실패했습니다',
  E1_CAPTCHA_REFRESH_REQUIRED: '캡차 로드 실패, 화면을 새로고침 해주세요',
  E1_NO_PERM: '접근 권한이 없습니다',
  E1_NO_SUCH_SEC_QUESTION: '등록된 보안 질문이 없습니다',
  E1_INVALID_SEC_ANSWER: '보안질문의 답변이 일치하지 않습니다',
  E1_BLOCKED_USER: '접근이 차단된 사용자입니다',
  E1_BLACK_USER_ID: '사용할 수 없는 사용자 ID입니다',
  E1_BLACK_NICK_NAME: '사용할 수 없는 닉네임입니다',
  E1_DELETED_USER: '탈퇴한 사용자입니다',
  E1_STOPPED_USER: '계정 사용이 중지된 사용자입니다',
  E1_DORMANT_USER: '휴면 상태의 사용자입니다',
  E1_BBS_POST_BLOCKED: '게시물 연속 작성으로 접근이 차단되었습니다',
  E1_BBS_REPLY_BLOCKED: '댓글 연속 작성으로 접근이 차단되었습니다',
  E1_NO_SUCH_ALLIANCE_INQ: '해당 제휴문의가 존재하지 않습니다',
  E1_NO_PERM_PRIVACY_HANDLE: '개인정보 취급 권한이 없습니다',
  E1_NO_PERM_MASTER: '최고 관리자 권한이 없습니다',

  E1_INVALID_CSV: 'CSV 포맷이 올바르지 않습니다',
  E1_NO_SUCH_DATA_FILE: '해당 데이터 파일이 존재하지 않습니다',
  E1_COLUMN_COUNT_INVALID: '컬럼의 개수가 맞지 않습니다',
  E1_MODEL_ALREADY_UPLOADED: '이미 모델 업로드가 완료되었습니다',
  E1_NO_SUCH_MODEL: '해당 모델이 존재하지 않습니다',
  E1_NO_SUCH_EDU_RES: '해당 교육자료가 없습니다',

  E1_USER_IGNORED: '사용자의 활동이 거부되었습니다',

  E1_NO_SUCH_GALLERY: '해당 작품이 존재하지 않습니다',
  E1_NO_SUCH_GALLERY_REPLY: '해당 댓글이 존재하지 않습니다',
  E1_DENY_SELF_CHIM: '본인의 게시물에는 관심 등록을 할 수 없습니다',
  E1_GALLERY_PROJECT_DUP: '이미 갤러리에 공유되었습니다',
  E1_CUSTOM: '커스텀 메시지 에러',

  E1_BLOCK_PREFIX_DUP: '블록 앞글자가 중복됩니다',
  E1_BLOCK_TYPE_DUP: '블록 타입이 중복됩니다',
  E1_BLOCK_PREFIX_INVALID: '블록 앞글자가 유효하지 않습니다',
  E1_TOO_MANY_SIMUL: '너무 많은 시뮬레이터가 존재합니다.',
  E1_NO_SUCH_SIMUL: '해당 시뮬레이터가 없습니다',
  E1_NO_SUCH_SIMUL_UNIT_BLOCK: '해당 단위 블록이 없습니다',
  E1_NO_SUCH_SIMUL_COMPOSITE_BLOCK: '해당 조합 블록이 없습니다',
  E1_NO_SUCH_SIMUL_BUTTON: '해당 버튼이 없습니다',
  E1_NO_SUCH_SIMUL_LABEL: '해당 레이블이 없습니다',
  E1_SIMUL_STATE_INVALID: '시뮬레이터의 상태가 올바르지 않습니다',
  E1_SIMUL_BLOCK_PREFIX_MISMATCH: '블록 앞글자가 일치하지 않습니다.',
  E1_SIMUL_INVALID_BLOCK_TYPE: '블록 타입이 올바르지 않습니다.',
  E1_NO_PUBLISH_ITEM: '배포할 항목이 없습니다',
  E1_UNIT_BLOCK_IN_USE: '조합 블록에서 사용중이므로 삭제할 수 없습니다',
  E1_SIMUL_DELETE_MARK_NEED: '완전히 제거하기 전에 삭제마킹을 해야 합니다',
  E1_NO_SUCH_SIMUL_PUB_FILE: '배포된 파일이 없습니다',
  E1_NO_SUCH_LESSON: '해당 수업지도안이 없습니다',

  E1_NO_SUCH_EVENT: '해당 이벤트가 없습니다.',
  E1_NO_SUCH_EVENT_APPLICANT: '해당 이벤트 응모자가 없습니다.',
  E1_EVENT_FIELD_LABEL_INVALID: '응모 양식의 레이블이 올바르지 않습니다.',
  E1_NO_EVENT_FIELDS: '이벤트 응모 항목이 없으면 이벤트를 활성화 할 수 없습니다.',
  E1_EVENT_APPLICANTS_EXISTS: '이벤트 응모한 사람이 존재합니다.',
  E1_NO_EVENT_APPLICANTS: '이벤트 응모자가 없습니다.',
  E1_NOT_EVENT_APPLICATION_PERIOD: '이벤트 응모 기간이 아닙니다.',
  E1_NOT_EVENT_APPLICATION_EDIT_PERIOD: '응모 정보를 수정할 수 있는 기간이 지났습니다.',
  E1_EVENT_DISABLED: '이벤트가 비활성화 상태입니다',

  E1_BLOCKED_ATTACH_FILE: '허용되지 않는 첨부파일입니다.',
  E1_DENY_RECENT_PW: '최근에 사용했던 비밀번호로 변경할 수 없습니다.',
  E1_KMCIS_ALREADY_USED: '휴대폰 인증을 다시 실행해주세요',
  E1_KMCIS_NOT_ACCEPTED: '휴대폰 인증을 실행해주세요',

  E1_HW_DRIVER_NAME_DUP: '이미 사용중인 드라이버 이름입니다',
  E1_EDU_TOY_DUP: '이미 등록된 하드웨어입니다',
  E1_NO_SUCH_EDU_TOY: '해당 하드웨어가 없습니다',
  E1_HW_DRIVER_FILE_OS_ARCH_DUP: '드라이버 파일의 OS 또는 아키텍처가 중복됩니다',
  E1_NO_PROPER_HW_DRIVER: '적절한 드라이버가 없습니다.',
  // web prop
  E1_NO_SUCH_WEB_PROP: '해당 설정이 없습니다.',
  E1_WEB_PROP_DUP: '이미 존재하는 설정입니다.',
  // dati
  E1_DATI_DOMAIN_NOT_FOUND: '도메인이 없습니다.',
  E1_DATI_NOT_FOUND: '다티가 없습니다.',
  E1_DATI_DUP: '다티가 이미 존재합니다.',
  E1_DATI_INVALID_DATI_ID: '다티 ID가 유효하지 않습니다.',
  E1_DATI_DOMAIN_DUP: '도메인이 이미 존재합니다.',
  E1_DATI_FOLDER_EXISTS: '폴더가 이미 존재합니다.',
  E1_DATI_FILE_EXISTS: '파일이 이미 존재합니다.',
  E1_DATI_FILE_NOT_EXISTS: '파일이 존재하지 않습니다.',
  E1_DATI_FOLDER_NOT_EXISTS: '폴더가 존재하지 않습니다.',
  E1_DATI_DST_PATH_INVALID: '목적지 경로가 유효하지 않습니다.',
  //
  E1_INVALID_NICK_NAME: '닉네임이 올바르지 않습니다',
  E1_INVALID_USER_ID: '사용자ID가 올바르지 않습니다',
  E1_DEVICE_CLOCK_INVALID: '접속하신 장치의 시간을 현재 시간으로 설정해주세요',
  E1_GAL_PROJ_DUP: '이미 등록된 작품입니다',
};

const localErrorMessage: Record<string, string> = {
  E0_GEN_FILE_INVALID: 'GEN 파일 형식이 올바르지 않습니다',
  E0_GEN_WILL_BE_SUPPORT: '해당 GEN 파일은 곧 지원예정입니다. 조금만 기다려주세요',
};

export const errorMessages = { ...serverErrorMessages, ...localErrorMessage };

export const errorToMessage = (errorCode: string): string => {
  const msg = errorMessages[errorCode];
  if (msg) {
    return msg;
  }

  if (errorCode.startsWith && errorCode.startsWith('E1_HTTP_')) {
    return `HTTP ${errorCode.substring('E1_HTTP_'.length)}`;
  }

  return msg ? msg : errorCode;
};
