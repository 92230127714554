import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@ocodelib/api-common';
import type { CodingImageCateForAdm } from '../model/index';

export class CodingImageCateApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  list = (
    params: {
      isBg: boolean;
    } & BaseRequest,
  ): Promise<{ cateList: CodingImageCateForAdm[] }> => {
    const url = '/admin/coding-img-cate/list';
    return this.withData.postJson(url, ...splitParams(params));
  };

  info = (
    params: {
      cateId: string;
    } & BaseRequest,
  ): Promise<{ cate: CodingImageCateForAdm }> => {
    const url = '/admin/coding-img-cate/info';
    return this.withData.post(url, ...splitParams(params));
  };

  create = (
    params: {
      cateId: string;
      cateName: string;
      isBg: boolean;
    } & BaseRequest,
  ): Promise<{ cate: CodingImageCateForAdm }> => {
    const url = '/admin/coding-img-cate/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  update = (
    params: {
      cateId: string;
      cateName: string;
      disabled: boolean;
    } & BaseRequest,
  ): Promise<{ cate: CodingImageCateForAdm }> => {
    const url = '/admin/coding-img-cate/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  delete = async (
    params: {
      cateId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-img-cate/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  updateDisabled = async (
    params: {
      cateId: string;
      disabled: boolean;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-img-cate/update-disabled';
    await this.helper.post(url, ...splitParams(params));
  };
}
