export type BbsSortTypeKey = 'SEEN' | 'RECENT' | 'REPLY';
export const BbsSortType: Record<BbsSortTypeKey, string> = {
  RECENT: '최신순',
  SEEN: '조회순',
  REPLY: '댓글순',
};

export type BbsQueryFieldKey = 'ALL' | 'TITLE' | 'WRITE_USER_NAME';
export const BbsQueryField: Record<BbsQueryFieldKey, string> = {
  ALL: '전체',
  TITLE: '제목',
  WRITE_USER_NAME: '작성자',
};

export type BbsKindKey = 'common' | 'band';
export const BbsKind: Record<BbsKindKey, string> = {
  common: '일반',
  band: '밴드',
};

export type BbsStateKey = 'ACTIVE' | 'RD_ONLY' | 'HIDDEN';
export const BbsState: Record<BbsStateKey, string> = {
  ACTIVE: '활성', // list,read,write BbsPostPolicy 정책에 따름
  RD_ONLY: '읽기 전용', // 쓰기 불가능
  HIDDEN: '숨김', // 숨김 상태에서는 읽기쓰기 불가능
};

export type BbsPostStateKey = 'OK' | 'HIDDEN' | 'REJECT';
export const BbsPostState: Record<BbsPostStateKey, string> = {
  OK: '정상',
  HIDDEN: '숨김', // 숨김 상태, 본인이 게시물을 감춘 경우
  REJECT: '삭제', // 승인 거부, 관리자가 게시물을 감춘 경우
};

/**
 * 게시물 조회 권한(목록 조회, 상세 조회)
 */
export type BbsPostReadPolicyKey = 'GUEST' | 'LOGIN' | 'SELF' | 'OWNER' | 'ADM';
export const BbsPostReadPolicy: Record<BbsPostReadPolicyKey, string> = {
  GUEST: '손님',
  LOGIN: '로그인 사용자', // 밴드 게시판은 밴드사용자들만 쓰기 가능
  SELF: '본인',
  OWNER: '소유자',
  ADM: '시스템',
};

/**
 * 게시물 또는 쓰기
 */
export type BbsPostWritePolicyKey = 'LOGIN' | 'OWNER' | 'ADM';
export const BbsPostWritePolicy: Record<BbsPostWritePolicyKey, string> = {
  LOGIN: '로그인 사용자', // 밴드 게시판은 밴드사용자들만 쓰기 가능
  OWNER: '소유자',
  ADM: '시스템',
};

export type BbsPostFileKindKey = 'ATTACH' | 'CONTENT';
