import * as CryptoJS from 'crypto-js';
import { randomChoice } from './util';

export class AESCrypto {
  private secretKey: CryptoJS.lib.WordArray;
  private iv: CryptoJS.lib.WordArray;

  readonly sid: number;

  constructor(sid: number, secretKey: CryptoJS.lib.WordArray, iv: CryptoJS.lib.WordArray) {
    this.sid = sid;
    this.secretKey = secretKey;
    this.iv = iv;
  }

  static createFromBase64(sid: number, base64Key: string, base64Iv: string): AESCrypto {
    const decodedKey = CryptoJS.enc.Base64.parse(base64Key);
    const decodedIv = CryptoJS.enc.Base64.parse(base64Iv);
    return new AESCrypto(sid, decodedKey, decodedIv);
  }

  static createFromKeyParams(keyParams: string): AESCrypto {
    const [sidStr, b64Key, b64Iv] = keyParams.split(';') as [string, string, string];
    const sid = +sidStr;
    if (isNaN(sid)) {
      throw new Error('invalid key:' + keyParams);
    }
    return AESCrypto.createFromBase64(sid, b64Key, b64Iv);
  }

  static createRandom = (keys: string[]) => {
    const key = randomChoice(keys);
    let [sidStr, base64Key, base64Iv] = key.split(';') as [string, string, string];
    const sid = +sidStr;
    if (isNaN(sid)) {
      throw new Error('invalid key:' + key);
    }
    return AESCrypto.createFromBase64(sid, base64Key, base64Iv);
  };

  encrypt = (message: string): string => {
    const encrypted = CryptoJS.AES.encrypt(message, this.secretKey, {
      iv: this.iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    return encrypted.toString();
  };

  decrypt = (encryptedMessage: string): string => {
    const decrypted = CryptoJS.AES.decrypt(encryptedMessage, this.secretKey, {
      iv: this.iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  };
}

// // Usage example
// const base64Key = '8f4e12a7dfe25a4f6a7cb2a7bff58dffb2b4f96b1bcb124f4fd3626e8a96d759'; // Example key
// const base64Iv = '4b5f70605e16af63984328509b1a18f7'; // Example IV

// const aes = AESCrypto.createFromBase64(base64Key, base64Iv);

// const message = 'Hello, AES Encryption!';
// const encryptedMessage = aes.encrypt(message);
// console.log('Encrypted Message:', encryptedMessage);

// const decryptedMessage = aes.decrypt(encryptedMessage);
// console.log('Decrypted Message:', decryptedMessage);
