export type GalleryStampKindKey = 'NORMAL' | 'GREAT' | 'POPULAR';

export const GalleryStampKind: Record<GalleryStampKindKey, string> = {
  GREAT: '우수',
  POPULAR: '인기',
  NORMAL: '일반',
};

export type GalleryApprovalStateKey = 'PENDING' | 'REJECT' | 'OK';
export const GalleryApprovalState: Record<GalleryApprovalStateKey, string> = {
  PENDING: '승인 대기',
  REJECT: '승인 거부',
  OK: '승인',
};

export type GalleryDomainKey = 'DEFAULT' | 'MILK';

export type GalleryKeywordFieldKey = 'ALL' | 'TITLE' | 'WRITER_NAME';
export const GalleryKeywordField: Record<GalleryKeywordFieldKey, string> = {
  ALL: '전체', // 제목 또는 작성자 이름으로 검색
  TITLE: '제목',
  WRITER_NAME: '작성자이름',
};
