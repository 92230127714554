// https://support.microsoft.com/en-us/office/blocked-attachments-in-outlook-434752e1-02d3-4e90-9124-8b81e49a8519
const BLOCKED_EXT = new Set([
  'ade',
  'adp',
  'app',
  'asp',
  'aspx',
  'asx',
  'bas',
  'bat',
  'cer',
  'chm',
  'cmd',
  'cnt',
  'com',
  'cpl',
  'crt',
  'csh',
  'der',
  'diagcab',
  'dll',
  'dmg',
  'docm',
  'exe',
  'fxp',
  'gadget',
  'grp',
  'hlp',
  'hpj',
  'hta',
  'htc',
  'html',
  'inf',
  'ins',
  'isp',
  'its',
  'jar',
  'jnlp',
  'js',
  'jse',
  'jsp',
  'ksh',
  'lnk',
  'mad',
  'maf',
  'mag',
  'mam',
  'maq',
  'mar',
  'mas',
  'mat',
  'mau',
  'mav',
  'maw',
  'mcf',
  'mda',
  'mdb',
  'mde',
  'mdt',
  'mdw',
  'mdz',
  'msc',
  'msh',
  'msh1',
  'msh1xml',
  'msh2',
  'msh2xml',
  'mshxml',
  'msi',
  'msp',
  'mst',
  'msu',
  'ops',
  'osd',
  'pcd',
  'pif',
  'pl',
  'plg',
  'prf',
  'prg',
  'printerexport',
  'ps1',
  'ps1xml',
  'ps2',
  'ps2xml',
  'psc1',
  'psc2',
  'psd1',
  'psdm1',
  'pst',
  'py',
  'pyc',
  'pyo',
  'pyw',
  'pyz',
  'pyzw',
  'reg',
  'scf',
  'scr',
  'sct',
  'sh',
  'shb',
  'shs',
  'theme',
  'tmp',
  'url',
  'vb',
  'vbe',
  'vbp',
  'vbs',
  'vhd',
  'vhdx',
  'vsmacros',
  'webpnp',
  'website',
  'ws',
  'wsc',
  'wsf',
  'wsh',
  'xbap',
  'xll',
  'xml',
  'xnk',
  'xps',
]);

export function isBlockedAttachFileName(fileName: string): boolean {
  if (fileName.endsWith('.') || !fileName.includes('.')) return false;
  const idx = fileName.lastIndexOf('.');
  if (idx < 0) return false;
  const ext = fileName.substring(idx + 1).toLowerCase();
  return BLOCKED_EXT.has(ext);
}

export const DATI_BLOCKED_EXT = new Set(Array.from(BLOCKED_EXT));
DATI_BLOCKED_EXT.delete('html');
DATI_BLOCKED_EXT.delete('htm');
DATI_BLOCKED_EXT.delete('js');
DATI_BLOCKED_EXT.delete('py');
DATI_BLOCKED_EXT.delete('xml');

export function isDatiBlockedFileName(fileName: string): boolean {
  if (fileName.endsWith('.') || !fileName.includes('.')) return false;
  const idx = fileName.lastIndexOf('.');
  if (idx < 0) return false;
  const ext = fileName.substring(idx + 1).toLowerCase();
  return DATI_BLOCKED_EXT.has(ext);
}
