import * as cryptojs from 'crypto-js';

export class SharedAES {
  static #KEY_SIZE = 256 / 32; // Key size in words (256 bits = 8 words)
  static #ITERATIONS = 65536;
  // private static SALT = CryptoJS.enc.Utf8.parse('shared_salt_value'); // Fixed salt for demonstration

  #pw: string;

  #key: cryptojs.lib.WordArray | undefined;

  #salt: cryptojs.lib.WordArray;

  constructor(pw: string, salt: string) {
    this.#pw = pw;
    this.#salt = cryptojs.enc.Utf8.parse(salt);
  }

  private get key() {
    if (this.#key) {
      return this.#key;
    }
    this.#key = cryptojs.PBKDF2(this.#pw, this.#salt, {
      keySize: SharedAES.#KEY_SIZE,
      iterations: SharedAES.#ITERATIONS,
      hasher: cryptojs.algo.SHA256,
    });
    return this.#key;
  }

  // Generate AES key from password and salt
  // static generateKeyFromPassword(password: string, salt: string): CryptoJS.lib.WordArray {
  //   return CryptoJS.PBKDF2(password, this.SALT, {
  //     keySize: this.KEY_SIZE,
  //     iterations: this.ITERATIONS,
  //     hasher: CryptoJS.algo.SHA256,
  //   });
  // }

  // Encrypt message using AES key
  encrypt = (message: string): string => {
    return cryptojs.AES.encrypt(message, this.key, {
      mode: cryptojs.mode.ECB,
      padding: cryptojs.pad.Pkcs7,
    }).toString();
  };

  // Decrypt message using AES key
  decrypt = (encryptedMessage: string): string => {
    return cryptojs.AES.decrypt(encryptedMessage, this.key, {
      mode: cryptojs.mode.ECB,
      padding: cryptojs.pad.Pkcs7,
    }).toString(cryptojs.enc.Utf8);
  };
}

let sharedInstance: SharedAES | undefined;

export function getSharedAES(): SharedAES {
  if (sharedInstance) return sharedInstance;
  sharedInstance = new SharedAES('foo', 'bar');
  return sharedInstance;
}

// Example usage
// function main() {
//     const password = 'foo'; // Shared password

//     // Generate AES key from password
//     const key = AESUtil.generateKeyFromPassword(password);

//     // Encrypt and decrypt a message
//     const originalMessage = 'Hello, AES Encryption!';
//     const encryptedMessage = AESUtil.encrypt(originalMessage, key);
//     console.log('Encrypted Message:', encryptedMessage);

//     const decryptedMessage = AESUtil.decrypt(encryptedMessage, key);
//     console.log('Decrypted Message:', decryptedMessage);
// }
