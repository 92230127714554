export type TextBreedKey = 'py' | 'p5' | 'js';
export const TextBreeds: Record<TextBreedKey, string> = {
  py: 'Python',
  js: 'Javascript',
  p5: 'P5.js',
};

export type BlkBreedKey = 'blox';
export const BlkBreeds: Record<BlkBreedKey, string> = {
  blox: 'BlockCoding',
};

export type ProjectBreedKey = TextBreedKey | BlkBreedKey;
export const ProjectBreeds: Record<ProjectBreedKey, string> = {
  ...TextBreeds,
  ...BlkBreeds,
};
