import {
  ApiHelperWithData,
  BaseRequest,
  splitParams,
  type ApiHelper,
  type PagingRequest,
} from '@ocodelib/api-common';
import type { UserAccountStateKey } from '../enum-types/index';
import type { PagerData, UserAccountForAdmin } from '../model/index';

/**
 * User Api
 */
export class UserApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 사용자 목록 조회
   */
  userList = async (
    params: {
      loginId?: string;
      nickName?: string;
      // studentGrades?: StudentGradeKey[];
      userAccountState?: UserAccountStateKey[];
      // authProviders?: AppAuthProviderKey[];
      sortKey?: 'RECENT' | 'PROFILE_IMG';
    } & PagingRequest,
  ): Promise<{ pagerData: PagerData<UserAccountForAdmin> }> => {
    const url = '/admin/user/list';
    const response: { pagerData: PagerData<UserAccountForAdmin> } = await this.withData.postJson(
      url,
      ...splitParams(params),
    );
    // response.pagerData.elements?.forEach((item) => {
    //   WasUrlHelper.replaceRelative(item, "profileImageUrl");
    // });
    return response;
  };

  /**
   * 사용자 정보 조회
   */
  userInfo = async (
    params: { accountId: number } & BaseRequest,
  ): Promise<{ userInfo: UserAccountForAdmin }> => {
    const { accountId, ...rest } = params;
    const url = `/admin/user/info/${accountId}`;

    const response: { userInfo: UserAccountForAdmin } = await this.withData.post(
      url,
      ...splitParams(rest),
    );
    // WasUrlHelper.replaceRelative(response.userInfo, "profileImageUrl");
    return response;
  };

  // /**
  //  * 사용자 활동 이력 조회
  //  */
  // activities = (
  //   params: { accountId: number } & BaseRequest,
  // ): Promise<{ activityList: UserActivity[] }> => {
  //   const url = "/admin/user-activity/list";

  //   return this.withData.post(url, ...splitParams(params));
  // };

  /**
   * 사용자 계정 unlock
   */
  unlockUser = async (params: { accountId: number } & BaseRequest): Promise<void> => {
    const url = '/admin/user/unlock';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * 사용자 비밀번호 변경
   */
  updateUserPw = async (
    params: { accountId: number; pw: string } & BaseRequest,
  ): Promise<{ userInfo: UserAccountForAdmin }> => {
    const url = '/admin/user/update-user-pw';
    const response: { userInfo: UserAccountForAdmin } = await this.withData.post(
      url,
      ...splitParams(params),
    );

    // WasUrlHelper.replaceRelative(response.userInfo, "profileImageUrl");
    return response;
  };

  /**
   * 사용자 프로필 사진 삭제
   */
  removeProfileImage = async (params: { accountId: number } & BaseRequest): Promise<void> => {
    const url = '/admin/user/remove-profile-image';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * 사용자 닉네임 업데이트
   */
  updateNickName = async (
    params: {
      accountId: number;
      nickName: string | null | undefined;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/user/update-nick-name';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * 사용자 기분 업데이트
   */
  updateMood = async (
    params: {
      accountId: number;
      mood: string | null | undefined;
    } & BaseRequest,
  ): Promise<void> => {
    const { accountId, ...rest } = params;
    const url = `/admin/user/update-user-mood/${accountId}`;
    await this.helper.post(url, ...splitParams(rest));
  };
}
