import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@ocodelib/api-common';
import type { CodingSoundKindKey } from '../enum-types/coding-sound-enum';
import type { CodingSoundForAdm, NextPageData, UploadedFileWithDuration } from '../model/index';

/**
 * CodingSound api
 */
export class CodingSoundApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  create = (
    params: {
      soundName: string;
      kind: CodingSoundKindKey;
      cateId: string;
      sortNumber?: number;
      fileId: string;
    } & BaseRequest,
  ): Promise<{ codingSound: CodingSoundForAdm }> => {
    const url = '/admin/coding-sound/create';
    return this.withData.postJson(url, ...splitParams(params));
  };

  update = (
    params: {
      soundId: number;
      soundName: string;
      kind: CodingSoundKindKey;
      fileId: string;
    } & BaseRequest,
  ): Promise<{ codingSound: CodingSoundForAdm }> => {
    const url = '/admin/coding-sound/update';
    return this.withData.postJson(url, ...splitParams(params));
  };

  updateInfo = (
    params: {
      soundId: number;
      soundName: string;
      kind: CodingSoundKindKey;
      cateId: string;
    } & BaseRequest,
  ): Promise<{ codingSound: CodingSoundForAdm }> => {
    const url = '/admin/coding-sound/update-info';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 주어진 카테고리의 kind 목록 조회
   * cateId가 전달되지 않으면 전체 kind 목록 조회
   */
  kindList = (
    params: {
      cateId?: string;
    } & BaseRequest,
  ): Promise<{ kindList: Array<{ key: string; label: string }> }> => {
    const url = '/admin/coding-sound/kind-list';
    return this.withData.post(url, ...splitParams(params));
  };

  search = (
    params: {
      keyword?: string;
      cateId?: string;
      kinds?: CodingSoundKindKey[];
      nextKey?: string;
    } & BaseRequest,
  ): Promise<{ pagerData: NextPageData<CodingSoundForAdm> }> => {
    const url = '/admin/coding-sound/search';
    return this.withData.postJson(url, ...splitParams(params));
  };

  info = async (
    params: {
      soundId: number;
    } & BaseRequest,
  ): Promise<{ codingSound: CodingSoundForAdm }> => {
    const url = '/admin/coding-sound/info';
    return this.withData.post(url, ...splitParams(params));
  };

  infoWithFile = async (
    params: {
      soundId: number;
    } & BaseRequest,
  ): Promise<{
    codingSound: CodingSoundForAdm;
    file: UploadedFileWithDuration;
    kindList: Array<{ key: string; label: string }>;
  }> => {
    const url = '/admin/coding-sound/info-with-file';
    return this.withData.post(url, ...splitParams(params));
  };

  uploadTempFile = async (
    params: {
      file: Blob;
      fileName: string;
      durationMs: number;
    } & BaseRequest,
  ): Promise<{ file: UploadedFileWithDuration }> => {
    const url = '/admin/coding-sound/upload';
    const { ctx, file, fileName, durationMs } = params;
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('durationMs', String(durationMs));
    return this.withData.postMultipart(url, formData, { ctx });
  };

  delete = async (
    params: {
      soundId: number;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-sound/delete';
    await this.helper.post(url, ...splitParams(params));
  };

  cateMove = async (
    params: {
      soundIds: number[];
      cateId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-sound/cate-move';
    return this.withData.postJson(url, ...splitParams(params));
  };

  sortInCate = async (
    params: {
      soundIds: number[];
      cateId: string;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/coding-sound/sort-in-cate';
    return this.withData.postJson(url, ...splitParams(params));
  };

  /**
   * 코딩 사운드의 파일 교체
   */
  replaceFileContent = (
    params: {
      fileId: string;
      file: File;
      durationMs: number;
    } & BaseRequest,
  ): Promise<{ soundUrl: string }> => {
    const url = '/admin/coding-sound/replace-file-content';
    const { ctx, file, fileId, durationMs } = params;
    const formData = new FormData();
    formData.append('durationMs', String(durationMs));
    formData.append('fileId', fileId);
    formData.append('file', file, file.name);
    return this.withData.postMultipart(url, formData, { ctx });
  };
}
