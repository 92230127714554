import {
  ApiHelperWithData,
  BaseRequest,
  splitParams,
  type ApiHelper,
  type PagingRequest,
} from '@ocodelib/api-common';
import type { UserKickReasonKey } from '../enum-types/index';
import type { PagerData, UserKick } from '../model/index';

/**
 * UserKick Api
 */
export class UserKickApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 사용자 차단 추가
   */
  add = async (
    params: {
      accountId: number;
      kickReason: UserKickReasonKey;
      memo?: string;
      kickMinute: number;
    } & BaseRequest,
  ): Promise<void> => {
    const url = '/admin/user-kick/add';
    await this.helper.postJson(url, ...splitParams(params));
  };

  /**
   * 사용자 차단 해제
   */
  cancel = async (params: { accountId: number } & BaseRequest): Promise<void> => {
    const url = '/admin/user-kick/cancel';
    await this.helper.post(url, ...splitParams(params));
  };

  /**
   * 사용자 차단 삭제
   */
  delete = async (params: { kickId: number } & BaseRequest): Promise<void> => {
    const { kickId, ...rest } = params;
    const url = `/admin/user-kick/delete/${kickId}`;
    await this.helper.post(url, ...splitParams(rest));
  };

  /**
   * 사용자 차단 이력 조회
   */
  list = (params: { accountId: number } & BaseRequest): Promise<{ kickList: UserKick[] }> => {
    const url = '/admin/user-kick/list';
    return this.withData.post(url, ...splitParams(params));
  };

  /**
   * 전체 사용자 차단 이력 조회 - 페이징
   */
  listAll = (
    params: { userId?: string } & PagingRequest,
  ): Promise<{ pagerData: PagerData<UserKick> }> => {
    const url = '/admin/user-kick/list-all';

    return this.withData.postJson(url, ...splitParams(params));
  };
}
