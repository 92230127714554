import { ApiHelper, ApiHelperWithData, BaseRequest, splitParams } from '@ocodelib/api-common';
import { getSharedAES } from '../crypto';
import type { CoppiaPub } from '../model/index';

export class CoppiaApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 공개키 발급
   */
  issue = async (params: BaseRequest): Promise<CoppiaPub> => {
    const url = '/p/api/key-pair/issue';
    const { encrypted } = (await this.withData.post(url, ...splitParams(params))) as {
      encrypted: string;
    };
    const aes = getSharedAES();
    const decrypted = aes.decrypt(encrypted);
    return JSON.parse(decrypted);
  };

  /**
   * 디크립트, 개발 테스트용
   * 보안문제가 있으니까, 필요할때 주석을 해제해서 테스트하자
   */
  decrypt = (
    params: {
      cid: number;
      encryptedMessageBase64: string;
    } & BaseRequest,
  ): Promise<{ text: string }> => {
    const url = '/p/api/key-pair/decrypt';
    return this.withData.post(url, ...splitParams(params));
  };
}
