import { ApiHelperWithData, BaseRequest, splitParams, type ApiHelper } from '@ocodelib/api-common';
import type { UserDati } from '../model/index';

export class UserDatiApi {
  private withData: ApiHelperWithData;

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  getOrCreateUserDati = (
    params: {
      domainId: string; // js,py,
    } & BaseRequest,
  ): Promise<{ userDati: UserDati }> => {
    const url = '/api/user-dati/get-or-create';
    return this.withData.post(url, ...splitParams(params));
  };
}
