import { ApiHelper, ApiHelperWithData, BaseRequest } from '@ocodelib/api-common';
import type {
  BrowserCount,
  BrowserDailyCountWithUser,
  BrowserMonthlyCountWithUser,
  DevicePlatformCount,
  DeviceTypeCount,
} from '../model/index';

/**
 * BrowserStat Api
 */
export class BrowserStatApi {
  private withData: ApiHelperWithData;
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper);
  }

  /**
   * 일별 브라우저 접속 통계 조회
   */
  dailyList = (
    params: {
      recentDayCount: number;
    } & BaseRequest,
  ): Promise<{ dailyList: BrowserDailyCountWithUser[] }> => {
    const { ctx, recentDayCount } = params;
    const url = `/admin/browser-stats/daily/${recentDayCount}`;
    return this.withData.post(url, undefined, { ctx });
  };

  /**
   * 월별 브라우저 접속 통계 조회
   */
  monthlyList = (params: BaseRequest): Promise<{ monthlyList: BrowserMonthlyCountWithUser[] }> => {
    const url = '/admin/browser-stats/monthly';
    const { ctx } = params;
    return this.withData.post(url, undefined, { ctx });
  };

  browserCountList = (
    params: {
      recentDayCount: number;
    } & BaseRequest,
  ): Promise<{ browserCountList: BrowserCount[] }> => {
    const { ctx, recentDayCount } = params;
    const url = `/admin/browser-stats/browsers/${recentDayCount}`;
    return this.withData.post(url, undefined, { ctx });
  };

  deviceTypeCountList = (
    params: {
      recentDayCount: number;
    } & BaseRequest,
  ): Promise<{ deviceTypeCountList: DeviceTypeCount[] }> => {
    const { ctx, recentDayCount } = params;
    const url = `/admin/browser-stats/device-types/${recentDayCount}`;
    return this.withData.post(url, undefined, { ctx });
  };

  platformCountList = (
    params: {
      recentDayCount: number;
    } & BaseRequest,
  ): Promise<{ platformCountList: DevicePlatformCount[] }> => {
    const { ctx, recentDayCount } = params;
    const url = `/admin/browser-stats/platforms/${recentDayCount}`;
    return this.withData.post(url, undefined, { ctx });
  };
}
